// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-ar-js": () => import("./../../../src/pages/contact-us.ar.js" /* webpackChunkName: "component---src-pages-contact-us-ar-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-ar-js": () => import("./../../../src/pages/index.ar.js" /* webpackChunkName: "component---src-pages-index-ar-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-response-ar-js": () => import("./../../../src/pages/payment-response.ar.js" /* webpackChunkName: "component---src-pages-payment-response-ar-js" */),
  "component---src-pages-payment-response-en-js": () => import("./../../../src/pages/payment-response.en.js" /* webpackChunkName: "component---src-pages-payment-response-en-js" */),
  "component---src-pages-payment-response-js": () => import("./../../../src/pages/payment-response.js" /* webpackChunkName: "component---src-pages-payment-response-js" */),
  "component---src-pages-pricing-ar-js": () => import("./../../../src/pages/pricing.ar.js" /* webpackChunkName: "component---src-pages-pricing-ar-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-ar-js": () => import("./../../../src/pages/privacy-policy.ar.js" /* webpackChunkName: "component---src-pages-privacy-policy-ar-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-tour-ar-js": () => import("./../../../src/pages/product-tour.ar.js" /* webpackChunkName: "component---src-pages-product-tour-ar-js" */),
  "component---src-pages-product-tour-en-js": () => import("./../../../src/pages/product-tour.en.js" /* webpackChunkName: "component---src-pages-product-tour-en-js" */),
  "component---src-pages-product-tour-js": () => import("./../../../src/pages/product-tour.js" /* webpackChunkName: "component---src-pages-product-tour-js" */),
  "component---src-pages-terms-ar-js": () => import("./../../../src/pages/terms.ar.js" /* webpackChunkName: "component---src-pages-terms-ar-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */)
}

